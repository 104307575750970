<template>
  <v-card elevation="4">
    <v-card-title class="h6 pb-6 font-weight-bold"
      >{{ $t('heading.instance.advanced.siteDetails.card.installation.title') }}</v-card-title
    >
    <v-card-text class="pt-2">
      <template v-if="loading">
        <v-skeleton-loader type="text@2" />
        <v-skeleton-loader type="text@2" />
        <v-skeleton-loader type="text@2" />
        <v-skeleton-loader type="text@2" />
      </template>
      <template v-else>
        <div class="details-box">
          <div class="details">
            <span class="details-label">{{ $t('heading.instance.advanced.siteDetails.card.installation.directory') }}</span>
            <span class="details-value heading--text font-weight-bold">
              {{ installationDetails.directory }}
            </span>
          </div>
          <div class="d-flex flex-column p-3 details">
            <span class="details-label">{{ $t('heading.instance.advanced.siteDetails.card.installation.database.name') }}</span>
            <span class="details-value heading--text font-weight-bold">
              {{ installationDetails.database_name }}
            </span>
          </div>
          <div class="d-flex flex-column p-3 details">
            <span class="details-label">{{ $t('heading.instance.advanced.siteDetails.card.installation.database.user') }}</span>
            <span class="details-value heading--text font-weight-bold">
              {{ installationDetails.database_user }}
            </span>
          </div>
          <div class="d-flex flex-column p-3 details">
            <span class="details-label">{{ $t('heading.instance.advanced.siteDetails.card.installation.database.password') }}</span>
            <span class="details-value heading--text font-weight-bold">
              {{ installationDetails.database_password }}
            </span>
          </div>
          <div class="d-flex flex-column p-3 details">
            <span class="details-label">{{ $t('heading.instance.advanced.siteDetails.card.installation.database.host') }}</span>
            <span class="details-value heading--text font-weight-bold">
              {{ installationDetails.database_host }}
            </span>
          </div>
          <template v-if="installationDetails.redis_host">
            <div class="d-flex flex-column p-3 details">
              <span class="details-label">{{ $t('heading.instance.advanced.siteDetails.card.installation.database.host') }}</span>
              <span class="details-value heading--text font-weight-bold">
                {{ installationDetails.redis_host || "127.0.0.1" }}
              </span>
            </div>
            <div class="d-flex flex-column p-3 details">
              <span class="details-label">{{ $t('heading.instance.advanced.siteDetails.card.installation.database.port') }}</span>
              <span class="details-value heading--text font-weight-bold">
                {{ installationDetails.redis_port || "6379" }}
              </span>
            </div>
            <div class="d-flex flex-column p-3 details">
              <span class="details-label">{{ $t('heading.instance.advanced.siteDetails.card.installation.database.password') }}</span>
              <span
                class="details-value"
                :class="
                  installationDetails.redis_password
                    ? 'heading--text font-weight-bold'
                    : 'font-italic'
                "
              >
                {{ installationDetails.redis_password || $t('general.noPassword') }}
              </span>
            </div>
          </template>
        </div>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    installationDetails: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  display: flex;
  flex-direction: column;
  &:not(:first-child) {
    margin-top: 10px;
  }
  &-label {
  }
  &-value {
    font-weight: $font-weight-bold;
    margin-top: 4px;
    font-size: $font-size-base;
    line-height: $line-height-base;
  }
}

.v-skeleton-loader::v-deep {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  .v-skeleton-loader__text {
    &:first-child {
      height: 8px;
      width: 62px;
      margin: 6px 0 10px 0;
    }
    &:nth-child(2) {
      height: 10px;
      width: 148px;
      margin: 7px 0;
    }
  }
}
</style>