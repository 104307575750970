<template>
  <li :key="`${item} + ${index}`" class="p-2 font-weight-600 heading--text">
    <span class="text-truncate">{{ item }}</span>

    <copy-text-button :text="item" />
  </li>
</template>

<script>
import CopyTextButton from "./buttons/CopyTextButton.vue";
export default {
  components: { CopyTextButton },
  props: {
    item: String,
    index: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped lang="scss">
ul {
  li {
    padding-left: 16px;
    position: relative;
    display: flex;
    align-items: center;
    &::before {
      content: "";
      position: absolute;
      top: calc(50% - 3px);
      left: 0px;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background: var(--v-info-base);
    }
  }
}
</style>