<template>
  <div>
    <v-form v-if="inputOpen || loading" ref="form" class="info-panel__input">
      <v-text-field
        dense
        class="mt-0 mr-2 mb-0"
        v-model="editInput"
        :rules="inputRules"
        hide-details=""
        @keydown.enter.prevent="changeInput"
        :loading="loading"
        :disabled="loading"
      ></v-text-field>
      <v-icon
        class="ml-1 edit-icon edit-icon--submit"
        size="24"
        @click="changeInput"
        :disabled="loading"
      >
        $check
      </v-icon>
      <v-icon
        size="24"
        class="edit-icon edit-icon--cancel"
        @click="inputOpen = false"
        :disabled="loading"
      >
        $close
      </v-icon>
    </v-form>
    <div v-else-if="instance.getUserPrivileges('wordpress.update_site_name')">
      <span class="p-2 heading--text dotted-underscore">
        {{ inputValue }}
      </span>
    </div>
    <div
      v-else
      class="info-panel__data info-panel__data--name"
      @click="inputOpen = !inputOpen"
    >
      <span class="p-2 heading--text dotted-underscore">
        {{ inputValue }}
      </span>
      <v-icon class="info-panel__data-icon edit-icon edit-icon--edit" size="16">
        $edit
      </v-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    inputRules: [Object, Array],
    inputValue: String,
    loading: Boolean,
    instance: Object,
  },
  watch: {
     loading: function(newValue) {
      if(!newValue) {
        this.inputOpen = false
      }
    },
    inputOpen: function () {
      this.editInput = this.inputValue;
    },
  },
  data: function () {
    return {
      inputOpen: false,
      editInput: this.inputValue,
    };
  },
  methods: {
    changeInput: function () {
      this.$emit("update:inputValue", this.editInput);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-input::v-deep {
  margin-bottom: 0px !important;
  input {
    color: map-get($primary, darken1);
    font-size: $font-size-base !important;
    line-height: $line-height-base !important;
    font-weight: $font-weight-semibold !important;
  }
  .v-input__slot {
    min-height: 20px;
    max-height: 20px;
    margin: 0;
    margin-top: -2px;
    &::after {
      display: none;
    }
    &::before {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      border: none !important;
      border-bottom: 1px dashed map-get($primary, darken1) !important;
      bottom: -5px;
      transition: border-color 0.24s ease;
    }
  }
}

.info-panel__title {
  margin-bottom: 0px;
}

.edit-icon {
  cursor: pointer;
  color: map-get($gray, darken1);
  &--edit {
    &:hover {
      color: map-get($primary, base);
    }
  }
  &--submit {
    &:hover {
      color: map-get($primary, base);
    }
  }
  &--cancel {
    &:hover {
      color: map-get($error, base);
    }
  }
}

.info-panel__data {
  .v-icon {
    opacity: 0;

    margin-left: 8px;
    transition: opacity 0.24s ease;
  }
  &:hover {
    .v-icon {
      opacity: 1;
    }
  }
}

.info-panel__input {
  display: flex;
  align-items: center;
}
</style>