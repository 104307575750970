<template>
  <v-col cols="12" md="6" sm="12">
    <v-card elevation="4">
      <v-card-title class="h6 pb-6 font-weight-bold">
        {{ $t('heading.instance.advanced.siteDetails.card.title') }}
      </v-card-title>
      <v-card-text class="pt-2">
        <template v-if="!loading">
          <div class="details-box">
            <div class="p-3 details">
              <span class="details-label">{{ $t('heading.instance.advanced.siteDetails.card.site.domain') }}</span>
              <div>
                <span class="details-value heading--text font-weight-bold">
                  {{ instance.domain }}
                </span>
              </div>
            </div>
            <div class="p-3 details">
              <span class="details-label">{{ $t('heading.instance.advanced.siteDetails.card.site.url') }}</span>
              <div>
                <a
                  class="details-value info--text font-weight-bold"
                  :href="instance.url"
                  target="_blank"
                >
                  {{ instance.url }}
                </a>
              </div>
            </div>
            <div class="p-3 details">
              <span class="details-label">{{ $t('heading.instance.advanced.siteDetails.card.site.version') }}</span>
              <div>
                <span class="details-value heading--text font-weight-bold">{{
                  instance.status.wordpress
                }}</span>
              </div>
            </div>
            <div class="p-3 details">
              <span class="details-label">{{ $t('heading.instance.advanced.siteDetails.card.site.siteName') }}</span>
              <div>
                <span class="details-value heading--text font-weight-bold">
                  <editable-header
                    ref="siteNameHeader"
                    v-on="$listeners"
                    :inputRules="siteNameRules"
                    :inputValue="instance.title"
                    :loading="nameChangeLoad"
                    :instance="instance"
                  />
                </span>
              </div>
            </div>
            <div class="p-3 details">
              <span class="details-label">{{ $t('heading.instance.advanced.siteDetails.card.site.created') }}</span>
              <div>
                <span class="details-value heading--text font-weight-bold">{{
                  instance.created_at_from_now
                }}</span>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <v-skeleton-loader type="text@2" />

          <v-skeleton-loader type="text@2" />

          <v-skeleton-loader type="text@2" />

          <v-skeleton-loader type="text@2" />

          <v-skeleton-loader type="text@2" />
        </template>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import EditableHeader from "../../inputs/EditableHeader.vue";
import FormRulesMixin from "../../../mixins/FormRulesMixin";

export default {
  components: {
    EditableHeader,
  },
  mixins: [FormRulesMixin],
  computed: {
    nameChangeLoad: function() {
      return this.$store.state.InstanceModule.nameChangingActionInProgress;
    }
  },  
  props: {
    instance: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  height: 100%;
  min-height: 364px;
}

.details {
  display: flex;
  flex-direction: column;
  &:not(:first-child) {
    margin-top: 10px;
  }
  &-label {
  }
  &-value {
    font-weight: $font-weight-bold;
    margin-top: 4px;
    font-size: $font-size-base;
    line-height: $line-height-base;
  }
}

.v-skeleton-loader::v-deep {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  .v-skeleton-loader__text {
    &:first-child {
      height: 8px;
      width: 62px;
      margin: 6px 0 10px 0;
    }
    &:nth-child(2) {
      height: 10px;
      width: 148px;
      margin: 7px 0;
    }
  }
}
</style>